import { createTheme } from "@mui/material/styles"

// Define the material ui theme
declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true
    vs: true
    ps: true
    ks: true
    ss: true
    sm: true
    tb: true
    md: true
    lg: true
    xl: true
  }
  interface Theme {
    name: string
  }
  interface ThemeOptions {
    name: string
  }
  interface TypeBackground {
    highlighted: string
  }
  interface TypeText {
    paper: string
    footer: string
  }
}

const colors = {
  white: "rgba(240,240,240,1)",
  offWhite: "rgba(240,240,243,1)",
  prettyGrey: "#ababa6",
  veryGrey: "#61615E",
  grey: "#3b3f44",
  darkGrey: "#1e1e1e",
  black: "rgba(0,0,0,1)",
  offBlack: "#1f1f1f",
  orange: "#ffa710",
  darkOrange: "#D48700",
  blue: "#185fd8",
  green: "#0FFFA7",
}

const typography = {
  h1: {
    fontSize: "2rem",
    fontWeight: 500,
  },
  h2: {
    fontSize: "1.5rem",
    fontWeight: 400,
  },
  h3: {
    fontSize: "1.25rem",
    fontWeight: 400,
  },
  body1: {
    fontSize: "1rem",
  },
  body2: {
    fontSize: "0.875rem",
  },
}

const breakpoints = {
  values: {
    xs: 0, // extra small
    vs: 319, // very small
    ps: 345, // pretty small
    ks: 382, // kinda small
    ss: 530, // slightly small
    sm: 632, // small
    tb: 750, // tablet
    md: 900, // medium
    lg: 1200, // large
    xl: 1536, // extra large
  },
}

const lightTheme = createTheme({
  name: "light",
  palette: {
    background: {
      default: colors.white,
      paper: colors.offWhite,
    },
    primary: {
      main: colors.darkOrange,
    },
    secondary: {
      main: colors.orange,
    },
    text: {
      primary: colors.offBlack,
      paper: colors.offBlack,
      footer: colors.veryGrey,
    },
  },
  typography: typography,
  breakpoints: breakpoints,
})

const darkTheme = createTheme({
  name: "dark",
  palette: {
    background: {
      default: colors.grey,
      paper: colors.darkGrey,
    },
    primary: {
      main: colors.orange,
    },
    secondary: {
      main: colors.orange,
    },
    text: {
      primary: colors.white,
      paper: colors.white,
      footer: colors.prettyGrey,
    },
  },
  typography: typography,
  breakpoints: breakpoints,
})

const themes = { light: lightTheme, dark: darkTheme }
export default themes
