import { ReactElement, useContext } from "react"
import { Typography, Box, useTheme, useMediaQuery } from "@mui/material"
import { WebPContext } from "../../App"

import Headline from "../../components/Headline"
import MediaCard from "../../components/MediaCard"
import ScrollList from "../../components/ScrollList"

import texecom from "../../assets/texecom_premier_LCDP.webp"
import detector from "../../assets/meq_blue.webp"

import texecom_png from "../../assets/png/texecom_premier_LCDP.png"
import detector_png from "../../assets/png/meq_blue.png"

/**
 * Page about Intruder Alarm systems.
 *
 * @returns The rendered Intruder Alarms page
 *
 */
function Intruder(): ReactElement {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down("tb"))
  const webP = useContext(WebPContext)
  return (
    <>
      <Headline title="Intruder Alarms" height="38px" width="45px" />

      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

        <Typography variant="body1" component="span" sx={{ textAlign: "left", paddingX: 1 }}>
          {isTablet ? null : (
            <MediaCard
              src={webP ? texecom : texecom_png}
              text="You will set and deactivate your alarm with an easy to use control panel."
              float="right"
              width="280px"
              height="230px"
            />
          )}
          City Alarms specialises in the installation, repair and routine maintenance of intruder
          alarm systems. All of our alarms will be specifically designed and installed to the
          requirements of each individual building.
          <ScrollList
            justifyContent="flex-start"
            items={[
              "24/7 remote alarm monitoring.",
              "Police approved.",
              "You will own the security system outright.",
              "Installation carried out to the latest British and European standards.",
              "Engineers have 10 year security clearance.",
              "No sub contractors used.",
            ]}
          ></ScrollList>
        </Typography>
        {isTablet ? (
          <>
            <br />
            <MediaCard
              src={webP ? texecom : texecom_png}
              text="You will set and deactivate your alarm with an easy to use control panel."
              flex={true}
            />
            <br />
          </>
        ) : null}

        <Typography variant="body1" component="span" sx={{ textAlign: "left", paddingX: 1 }}>
          {isTablet ? null : (
            <MediaCard
              src={webP ? detector : detector_png}
              text={"Infrared motion detectors will be installed."}
              float="left"
              width="250px"
              height="250px"
            />
          )}
          Depending on your requirements, we will install one of four grades of intruder alarm
          systems, as defined by the EN50131 European standard.
          <ScrollList
            justifyContent="flex-start"
            icon="shield"
            items={[
              <Typography>
                <span style={{ color: theme.palette.primary.main }}>Grade 1: Low Risk</span> -
                Appropriate only if your property is in a very low risk area and the main threat is
                from opportunistic thieves. This level of security is mostly associated with the DIY
                market and is not usually acceptable to insurers.
              </Typography>,
              <Typography>
                <span style={{ color: theme.palette.primary.main }}>Grade 2: Medium Risk</span> -
                The majority of systems we install are Grade 2. These systems are designed to be
                resilient to intruders who are familiar with how alarm systems work and may be
                equipped with specialist tools.
              </Typography>,
              <Typography>
                <span style={{ color: theme.palette.primary.main }}>Grade 3: High Risk</span> - For
                high risk homes or commercial properties, we recommend a Grade 3 system. This
                assumes that the intruders will have advanced knowledge, tools, and equipment,
                including portable electronic devices.
              </Typography>,
              <Typography>
                <span style={{ color: theme.palette.primary.main }}>Grade 4: Very High Risk</span> -
                The most sophisticated and advanced security systems available, appropriate for
                extremely high security properties such as government facilities, banks and museums.
              </Typography>,
            ]}
          ></ScrollList>
        </Typography>
        
      </Box>
      {isTablet ? (
        <MediaCard
          src={webP ? detector : detector_png}
          text={"Infrared motion detectors will be installed in most areas of the building."}
          flex={true}
          width="200px"
          height="200px"
        />
      ) : null}
    </>
  )
}

export default Intruder
