import { ReactElement, useContext } from "react"
import { Box, Typography, Link, useTheme, useMediaQuery } from "@mui/material"
import { WebPContext } from "../App"

import Headline from "../components/Headline"
import JobCard from "../components/JobCard"
import MediaCard from "../components/MediaCard"

import office from "../assets/newphotos/office.webp"
import office_png from "../assets/newphotos/office.jpg"

import engineers from "../assets/newphotos/engineers_cropped.webp"
import engineers_jpg from "../assets/newphotos/engineers_cropped.jpg"

/**
 * Careers page consisting of open job positions.
 *
 * @returns The rendered Careers page
 *
 */
function Careers(): ReactElement {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const webP = useContext(WebPContext)
  return (
    <>
      <Headline title="Careers at City Alarms" height="38px" width="38px" />

      <JobCard
        title="Apprentice Engineer"
        responsibilities={
          "Installing and servicing intruder alarms, fire alarms and CCTV, " +
          "responding to customer call outs."
        }
        experience={
          "No previous experience is required. If you are a promising candidate and pass our " +
          "background checks, we will pair you with a senior engineer while you learn on the job."
        }
      />

      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography variant="body1" component="span" sx={{ marginX: 1, textAlign: "left" }}>
          We are always looking for the right staff. Please consider the open positions and forward
          your CV to <Link href="mailto:info@cityalarms.com">info@cityalarms.com</Link>. Even if we
          are not looking for staff at present we will keep your details on file for future
          reference. Our office is located at{" "}
          <Link
            href="https://goo.gl/maps/mD5Dkw9RA5VNgpKw8"
            target="_blank"
            rel="noopener noreferrer"
          >
            142-144 Hillview Avenue, Hornchurch, Essex, RM11&nbsp;2DL
          </Link>
          .
          <br />
          <br />
          Open positions can range from administrative work to engineering roles, and cover varying
          levels of experience from apprentice to fully qualified senior positions. Please note that
          we have very high standards for our employees, and all applicants must pass police
          security clearance and undergo strict background checks.
          <br />
          <br />
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <MediaCard
          src={webP ? office : office_png}
          text="The City Alarms administrative team."
          flex={true}
          width="360px"
          height="250px"
        />
        {isMobile ? <br /> : null}
        <MediaCard
          src={webP ? engineers : engineers_jpg}
          text="The City Alarms engineering team."
          flex={true}
          width="360px"
          height="250px"
        />
      </Box>
    </>
  )
}

export default Careers
