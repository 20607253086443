import { ReactElement } from "react"
import { Typography, Link, useTheme, useMediaQuery } from "@mui/material"

import Headline from "../components/Headline"
import ContactButtons from "../components/ContactButtons"
import DesktopWelcome from "../components/DesktopWelcome"
import MobileWelcome from "../components/MobileWelcome"
import FeatureCard from "../components/FeatureCard"
import FeatureGrid from "../components/FeatureGrid"
import ScrollList from "../components/ScrollList"
import Accreditations from "../components/Accreditations"

/**
 * Home page consisting of basic company information, main services and selling points.
 *
 * @returns The rendered home page
 *
 */
function Home(): ReactElement {
  const theme = useTheme()
  const isSlightlySmall = useMediaQuery(theme.breakpoints.down("ss"))
  const isVerySmall = useMediaQuery(theme.breakpoints.down("vs"))

  const introText = `City Alarms is a family business that has been installing and maintaining
  state of the art security systems throughout London and the surrounding area for over 35 years.
  Whether you need a fresh installation, an upgrade for your current systems, or just need them
  maintained, we're here to help! Contact us now for a free quote or more information about any of
  our services.`

  return (
    <>
      {isSlightlySmall ? (
        isVerySmall ? (
          <>
            <Headline
              title="For All Your Security Needs"
              width="39px"
              height="39px"
              paddingBottom={2}
            />
            <ContactButtons marginY={0} />
            <br />
          </>
        ) : (
          <MobileWelcome />
        )
      ) : (
        <DesktopWelcome />
      )}
      <FeatureGrid sm={4} xs={12} spacing={2}>
        <FeatureCard
          title="Intruder Alarms"
          text="Get peace of mind with our
          intruder alarms and access
          control systems."
          url={"services/intruder"}
        />
        <FeatureCard
          title="Fire Alarms"
          text="Protect your home, business
          and loved ones with our
          fire detection systems."
          url={"services/fire"}
        />
        <FeatureCard
          title="CCTV"
          text="Boost your security and deter
          intruders with our range of
          cameras and covert CCTV."
          url={"services/cctv"}
        />
      </FeatureGrid>
      <Typography variant="body1" sx={{ textAlign: "left", marginX: 1, marginTop: 6 }}>
        {introText}
      </Typography>
      <Headline title="10 Reasons to Choose City&nbsp;Alarms" />
      <ScrollList
        items={[
          <Typography>
            We are endorsed by the{" "}
            <Link
              href="https://trustedtraders.which.co.uk/businesses/city-alarms-ltd/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Which?
            </Link>{" "}
            trusted trader scheme.
          </Typography>,
          <Typography>
            Our security systems have a{" "}
            <Link
              href="https://www.nsi.org.uk/company-finder/?scid=9366301d-4d05-ea11-80fd-00155d993d0a&type=quick&familyType=Security+Systems&town-postcode=&Lo=&La=&range=3&search=company&company-name=City+Alarms&regionId="
              target="_blank"
              rel="noopener noreferrer"
            >
              gold rating from NSI
            </Link>{" "}
            (the National Security Inspectorate).
          </Typography>,
          "We are a family run business that has been trading for over 35 years.",
          "Your alarm panel will be the same as our own office alarm.",
          "All our work is carried out by our own engineers. We do not use subcontractors.",
          "We only use 1 year contracts which can be cancelled at any time.",
          "You will have full ownership of the system, unlike many companies that surreptitiously only rent it to you.",
          "All our staff have police security clearance, and have passed 10 year background checks.",
          "You will receive a 2 year warranty on maintained systems.",
          "The majority of our work is through repeat business and recommendations. We do not advertise.",
        ]}
      />
      <Accreditations />
    </>
  )
}

export default Home
